import { fetchLatestCryptocurrencyListings } from "../../server/topCoinMarketCapApi";
import WebSocketManager from "../WebSocketManager";

const krakenUrl = 'wss://ws.kraken.com/v2';

// Subscription Candle data but does not have per(%)changes in this
const krakenSubscriptionCandleData = (symbolsArray)=>({
  method: 'subscribe',  //unsubscribe to stop receiving data
  params: {
    channel: 'ohlc',
    symbol: symbolsArray,
    interval: 1,
    snapshot: true,
  },
  req_id: 1,
});

//price data with 
const krakenPriceDataMsg =(symbolsArray) => ({
  method: "subscribe",
  params: {
    channel: "ticker",
    // symbol: ["BTC/USD","ETH/USD"]
    symbol: symbolsArray
  }
});


const KrakenWebSocket = {
 async connect(onMessageCallback) {
    try {
      const { success, response } = await fetchLatestCryptocurrencyListings();
      if (success) {
        
        // const symbols = response.data.data
        // .slice(0, 10)
        // .map(crypto => crypto.symbol + "/USDT")
        // .filter(symbol => symbol !== "USDT/USDT");

        const symbols =['BTC/USDT', 'ETH/USDT', 'BNB/USDT', 'SOL/USDT', 'USDC/USDT', 'XRP/USDT', 'DOGE/USDT', 'TON/USDT', 'ADA/USDT', 'TRX/USDT']
        
        const krakenPriceData = krakenPriceDataMsg(symbols); 
        WebSocketManager.onMessage(onMessageCallback);  // Set the message handler
        WebSocketManager.initConnection('kraken', krakenUrl, krakenPriceData); //which method to Subscription data put it here
      }
      else {
        console.error('Failed to fetch cryptocurrency symbols');
      }

    } catch (error) {
      console.error('Error connecting to Kraken WebSocket:', error.message);
    }
  },

  disconnect() {
    WebSocketManager.closeConnection('kraken');
  },
};

export default KrakenWebSocket;
