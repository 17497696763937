import React, { useState } from "react";
import DashboardLayout from "../components/DashboardLayout";
import SearchInput from "../components/Input";
import SortByDropdown from "../components/SortByDropdown";
import SavedSearchDropdown from "../components/SavedSearchDropdown";
import FilterIcon from "../components/FilterIcon";
import CryptoTable from "../components/CryptoTable";
import Sidebar from "../components/Sidebar"; // Import Sidebar component

const Dashboard = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("Dashboard");

  // Function to handle sidebar menu item click
  const handleMenuItemClick = (menuItem) => {
    setActiveMenuItem(menuItem);
  };

  return (
    <DashboardLayout>
      {/* Sidebar with a callback to handle menu clicks */}
      <div className="flex">
        <Sidebar onMenuItemClick={handleMenuItemClick} />

        {/* Main content */}
        <div className="flex-grow py-[40px] px-[20px] bg-[#343434]">
          {/* Show content based on active menu item */}
          {activeMenuItem === "Cryptocurrencies" ? (
            <>
              {/* Table Section */}
              <CryptoTable />
            </>
          ) : (
            <div className="text-white">
              <h1 className="text-[22px] leading-[30px] font-bold">
                {activeMenuItem}
              </h1>
              <p className="text-[#CCCCCC] font-light text-[14px] leading-[21px]">
                This is the {activeMenuItem} section. Content coming soon...
              </p>
            </div>
          )}
        </div>
      </div>
    </DashboardLayout>
  );
};

export default Dashboard;
