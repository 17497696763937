import React, { useState, useEffect } from "react";
import KrakenWebSocket from "../services/kraken/KrakenWebSocket";
import gateioWebSocket from "../services/gateio/gateiowebsocket";
import binanceWebSocket from "../services/binance/binancewebsocket";
import { fetchLatestCryptocurrencyListings } from "../server/topCoinMarketCapApi";
import NotificationComponent from "./NotificationComponent";
import htxWebSocket from "../services/htx/HtxWebSocket";
import { fetchKucoinBulletPublic } from "../services/kucoin/KuCoinServApi";
import kuCoinWebSocket, { getKucoinData } from "../services/kucoin/KuCoinWebSocket";
import bitfinexWebSocket from "../services/bitfinex/BitfinexWebSocket";
import { cryptoListPairSymbols } from "../services/CryptoPairSymbols";

const TableData = () => {
  const [tooltip, setTooltip] = useState(null);
  // const [krakenData, setKrakenData] = useState({});
  const [gateioData, setGateioData] = useState({});
  const [binanceData, setBinanceData] = useState({});
  const [kucoinData, setKucoinData] = useState({});
  const [htxData, setHtxData] = useState({});

  const [symbols, setSymbols] = useState([]);

  const handleClick = (priceData, event) => {
    // Stop event propagation to prevent immediate close
    event.stopPropagation();
    // Set the tooltip position and data
    setTooltip({
      priceData,
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".tooltip")) {
      setTooltip(null); // Close the tooltip when clicking outside
    }
  };

  const handleTopCurrency = async () => {
    const { success, response } = await fetchLatestCryptocurrencyListings();
    if (success) {
      // const symbols = response.data.data
      //   .slice(0, 11)
      //   .map(crypto => crypto.symbol + "/USDT")
      //   .filter(symbol => symbol !== "USDT/USDT");
      const symbols = cryptoListPairSymbols
      return symbols;
    }
    return [];
  };

  let dynamicQuoteCurrencies = [];

  // Function to fetch quote currencies dynamically
  const fetchQuoteCurrencies = async () => {
    // Simulate an API call to fetch known quote currencies
    const { success, response } = await fetchLatestCryptocurrencyListings();
    if (success) {
      const quotes = response?.data?.data.map((crypto) => crypto.symbol);
      dynamicQuoteCurrencies = [...new Set(quotes)];
      return dynamicQuoteCurrencies;
    }
    return [];
  };

  // Function to format trading pair dynamically
  const formatTradingPair = (pair) => {
    pair = pair?.toUpperCase();

    if (pair?.includes("_")) {
      return pair.replace("_", "/");
    }


    if (pair?.includes("-")) {
      return pair.replace("-", "/");
    }
    // Check if the pair is in the format "market.{symbol}.ticker"
    if (pair?.includes("MARKET.")) {

      const symbol = pair.split('.')[1].replace('.TICKER', '');

      const quoteCurrencyLength = 4; //(like "USDT", "USD", etc.)

      const baseCurrency = symbol.slice(0, -quoteCurrencyLength);
      const quoteCurrency = symbol.slice(-quoteCurrencyLength);
       
      return `${baseCurrency}/${quoteCurrency}`;
    }

    if (pair?.includes("/")) {
      return pair;
    }

    // Use dynamically fetched quote currencies
    fetchQuoteCurrencies();
    for (let quote of dynamicQuoteCurrencies) {
      if (pair?.endsWith(quote)) {
        const base = pair.substring(0, pair.length - quote.length);
        return `${base}/${quote}`;
      }
    }
    return pair;
  };

  useEffect(() => {
    const fetchSymbols = async () => {
      const fetchedSymbols = await handleTopCurrency();
      setSymbols(fetchedSymbols);

    };
    fetchSymbols();
    // getKucoinData();

    // Kraken WebSocket connection
    // KrakenWebSocket.connect((data) => {
    //   const krakenArray = Array.isArray(data?.data) ? data?.data : [];

    // Loop through each symbol and update the state dynamically
    //   krakenArray.forEach((item) => {
    //     setKrakenData((prevData) => ({
    //       ...prevData,
    //       [item?.symbol]: {
    //         lastPrice: item?.last,
    //         percentChange: item?.change_pct,
    //       },
    //     }));
    //   });
    // });

    // Binance WebSocket connection
    gateioWebSocket.connect((data) => {
      const formattedSymbol = formatTradingPair(data?.result?.currency_pair);

      setGateioData((prevData) => ({
        ...prevData,
        [formattedSymbol]: {
          lastPrice: data?.result?.last,
          percentChange: data?.result?.change_percentage,
        },
      }));
    });

    // Binance WebSocket connection
    binanceWebSocket.connect((data) => {
      const formattedSymbol = formatTradingPair(data?.s);

      setBinanceData((prevData) => ({
        ...prevData,
        [formattedSymbol]: {
          lastPrice: data?.c,
          percentChange: data?.P,
        },
      }));
    });

        // KuCoin WebSocket connection
        kuCoinWebSocket.connect((data) => {
          const formattedSymbol = formatTradingPair(data?.data?.data?.symbol);
          setKucoinData((prevData) => ({
            ...prevData,
            [formattedSymbol]: {
              lastPrice: data?.data?.data?.close,
              percentChange: data?.data?.data?.changeRate,
            },
          }));
        });

      // HTX WebSocket connection
      htxWebSocket.connect((data) => {
        const formattedSymbol = formatTradingPair(data?.ch);
        setHtxData((prevData) => ({
          ...prevData,
          [formattedSymbol]: {
            lastPrice: data?.tick?.lastPrice,
            percentChange: ((data?.tick?.close - data?.tick?.open) / data?.tick?.open) * 100,
          },
        }));
        console.log(formattedSymbol,"RETURNED SYMBOLS");
        
      });

    // BITFINEX WebSocket connection
    // bitfinexWebSocket.connect((data) => {
    // // const formattedSymbol = formatTradingPair(data?.result?.currency_pair); // Format the symbol
    //  console.log(data[1],"CHECKINGDATA");

    // // setGateioData((prevData) => ({
    // //   ...prevData,
    // //   [formattedSymbol]: {
    // //     lastPrice: data?.result?.last,
    // //     percentChange: data?.result?.change_percentage,
    // //   },
    // // }));
    //  });


    document.addEventListener("click", handleClickOutside);
    return () => {
      // Cleanup WebSocket connections on component unmount
      KrakenWebSocket.disconnect();
      gateioWebSocket.disconnect();
      binanceWebSocket.disconnect();
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  function formatToFixed(str) {
    if (!str) return "";
    str = str + "";
    // Split the string by the decimal point
    const parts = str.split(".");

    // Check if there are at least two parts
    if (parts.length > 1) {
      // Join all but the last part with a decimal point and keep the last part
      const numberPart =
        parts.slice(0, -1).join(".") + "." + parts[parts.length - 1];

      // Convert to number and format to 3 decimal places
      const formattedNumber = parseFloat(numberPart).toFixed(3);

      return formattedNumber;
    } else {
      // If there are no decimal points, just return the original string formatted to 3 decimal places
      return parseFloat(str).toFixed(3);
    }
  }
  // Individual disconnect handlers for each WebSocket
  const handleKrakenDisconnect = () => {
    KrakenWebSocket.disconnect();
  };

  const handleGateioDisconnect = () => {
    gateioWebSocket.disconnect();
  };

  const handleBinanceDisconnect = () => {
    binanceWebSocket.disconnect();
  };

  return (
    <div className="table-container rounded-[10px]">
      <NotificationComponent />
      <table className="crypto-table">
        <thead>
          <tr className="border-b border-0 border-solid">
            <th className="!font-thin">Currency Name</th>

            <th colSpan="2" className="!font-thin !h-2">
              GATE.IO
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              BINANCE
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              KUCOIN
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              HTX
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              UPBIT
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              COINBASE
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              BITFINEX
            </th>
          </tr>
          <tr className="border-b !font-thin">
            <th></th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
          </tr>
        </thead>
        <tbody className="text-center">
          {symbols.map((symbol, index) => {
            let newSymbol = symbol.replace("/", "");
            let newKucoinSymbol = symbol.replace("-", "/");
            return (
              <tr key={index} className="border-b">
                <td className="flex flex-row">
                  <span className="">🟠</span> {symbol}
                </td>

                {/* Kraken Price Section */}
                {/* <td
                  className="cursor-pointer"
                  onClick={(event) =>
                    handleClick(krakenData[symbol]?.lastPrice, event)
                  }
                >
                  {krakenData[symbol]?.lastPrice
                    ? "$" + krakenData[symbol]?.lastPrice.toFixed(3)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      krakenData[symbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {krakenData[symbol]?.percentChange
                    ? `${krakenData[symbol]?.percentChange.toFixed(3)}%`
                    : "--"}
                </td> */}

                {/* Gate.io Price Section */}
                <td
                  className="cursor-pointer"
                  onClick={(event) =>
                    handleClick(gateioData[symbol]?.lastPrice, event)
                  }
                >
                  {gateioData[symbol]?.lastPrice
                    ? "$" + formatToFixed(gateioData[symbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      gateioData[symbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {gateioData[symbol]?.percentChange
                    ? `${formatToFixed(gateioData[symbol]?.percentChange)}%`
                    : "--"}
                </td>

                {/* Binance Price Section */}
                <td
                  onClick={(event) =>
                    handleClick(binanceData[newSymbol]?.lastPrice, event)
                  }
                >
                  {binanceData[newSymbol]?.lastPrice
                    ? "$" + formatToFixed(binanceData[newSymbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4 cursor-pointer"
                  style={{
                    color:
                      binanceData[newSymbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {binanceData[newSymbol]?.percentChange
                    ? `${formatToFixed(binanceData[newSymbol]?.percentChange)}%`
                    : "--"}
                </td>

                {/* Kucoin Price Section */}
                <td
                  className="cursor-pointer"
                  onClick={(event) =>
                    handleClick(kucoinData[newKucoinSymbol]?.lastPrice, event)
                  }
                >
                  {kucoinData[newKucoinSymbol]?.lastPrice
                    ? "$" + formatToFixed(kucoinData[newKucoinSymbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      kucoinData[newKucoinSymbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {kucoinData[newKucoinSymbol]?.percentChange
                    ? `${formatToFixed(kucoinData[newKucoinSymbol]?.percentChange)}%`
                    : "--"}
                </td>

                {/* HTX Price Section */}
                <td
                  className="cursor-pointer"
                  onClick={(event) => {                    
                    handleClick(htxData[symbol]?.lastPrice, event)
                  }

                  }
                >
                  {htxData[symbol]?.lastPrice
                    ? "$" + formatToFixed(htxData[symbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      htxData[symbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {htxData[symbol]?.percentChange
                    ? `${formatToFixed(htxData[symbol]?.percentChange)}%`
                    : "--"}
                </td>

                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>
                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>

                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>
                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>

                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>
                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {tooltip && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: `${tooltip.y}px`,
            left: `${tooltip.x}px`,
            backgroundColor: "black",
            color: "white",
            padding: "16px",
            borderRadius: "10px",
            zIndex: 1000,
            whiteSpace: "nowrap",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent closing when interacting with the tooltip
        >
          <div>
            <p>
              Amount:{" "}
              <input
                type="number"
                placeholder="$"
                className="bg-transparent text-[#fff]"
              />
            </p>
            <p className="py-[10px]">4999 Coins</p>
            <div className="flex justify-between gap-[8px]">
              <button className="buy-btn bg-[#61C277] py-[5px] px-[20px] rounded-[7px] text-white uppercase w-full">
                Buy
              </button>
              <button className="sell-btn bg-[#FF3E3E] py-[5px] px-[20px] rounded-[7px] text-white uppercase w-full">
                Sell
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TableData;
