import axios from 'axios';

export const fetchLatestCryptocurrencyListings = async () => {
  try {
    // const response = await axios.get('http://localhost:5000/api/cryptocurrency/listings/latest');
    const response =['BTC', 'ETH', 'BNB', 'SOL', 'USDC', 'XRP', 'DOGE', 'TON', 'ADA', 'TRX']
    return { success: true, response }; 

  } catch (error) {
    console.error("Error fetching cryptocurrency data:", error.message);
    return { success: false, data: [] }; 
  }
};
