import { fetchLatestCryptocurrencyListings } from "../../server/topCoinMarketCapApi";
import { kucoinSymbols } from "../CryptoPairSymbols";
import WebSocketManager from "../WebSocketManagerKuCoin";
import { fetchKucoinBulletPublic } from "./KuCoinServApi";



//price data with 
const kuCoinPriceDataMsg =(symbolsArray) => 
    ({
        "type": "subscribe",
        "topic": `/market/snapshot:${symbolsArray}`, // Updated topic format for subscription
        "response": true
    });
    


const kuCoinWebSocket = {
 async connect(onMessageCallback) {
    try {
      const { success, response } = await fetchLatestCryptocurrencyListings();
      if (success) {
        // const symbols = response.data.data
        // .slice(0, 10)
        // .map(crypto => crypto.symbol + "_USDT")
        // .filter(symbol => symbol !== "USDT_USDT");

        const symbols = kucoinSymbols;

        
        // console.log(symbols,"SYMBOLS");
        const kuCoinPriceData = kuCoinPriceDataMsg(symbols);
        const urlTokenAuth = await getKucoinData(); 
        
        WebSocketManager.onMessage(onMessageCallback);  
        WebSocketManager.initConnection('kucoin', urlTokenAuth?.kuCoinAuthToken?.url, kuCoinPriceData, urlTokenAuth?.kuCoinAuthToken?.token); 
      }
      else {
        console.error('Failed to fetch cryptocurrency symbols');
      }

    } catch (error) {
      console.error('Error connecting to kucoin WebSocket:', error.message);
    }
  },

  disconnect() {
    WebSocketManager.closeConnection('kucoin');
  },
};


 const getKucoinData = async () => {
    const kucoinData = await fetchKucoinBulletPublic();

    if (kucoinData && kucoinData?.response && kucoinData?.response?.data) {
        const kuCoinAuthToken ={
            token : kucoinData?.response?.data?.data?.token,
            url : kucoinData?.response?.data?.data?.instanceServers[0]?.endpoint,
        }
        console.log(kuCoinAuthToken,"WEBSOCKET");
        
        return {success:true, kuCoinAuthToken}
    } else {
        console.error("Failed to fetch KuCoin data:", kucoinData);
    }
};


export default kuCoinWebSocket;
